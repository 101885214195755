<template>
  <b-modal
    :id="modalId"
    :visible="modalVisibility(modalId)"
    title="Company Registration Info"
    @show="onShow"
    @hidden="resetModal"
    size="lg"
    no-close-on-backdrop
  >
    <KTCard v-if="!isLoading" title="Company Details">
      <template v-slot:body>
        <div>
          <b-row align-v="stretch">
            <!-- Company Details -->
            <b-col sm="6">
              <b-card title-tag="h6" title="Details" class="mb-4">
                <base-input
                  v-model="companyTempRegInfo.company_name"
                  label="Name"
                  :disabled="true"
                ></base-input>
                <base-input
                  v-model="companyTempRegInfo.ssm"
                  label="Registration No"
                  :disabled="true"
                ></base-input>
              </b-card>
            </b-col>
            <!-- END Company Details -->

            <!-- Person In Charge -->
            <b-col sm="6">
              <b-card class="mb-4" title-tag="h6" title="Person In Charge">
                <base-input
                  v-model="companyTempRegInfo.pic_name"
                  label="Name"
                  :disabled="readOnly"
                ></base-input>
                <base-input
                  v-model="companyTempRegInfo.pic_email"
                  label="Email"
                  :disabled="readOnly"
                ></base-input>
                <base-input
                  v-model="companyTempRegInfo.pic_phone_number"
                  label="Phone No"
                  :disabled="readOnly"
                ></base-input>
              </b-card>
            </b-col>
            <!-- END Person In Charge -->
          </b-row>
          <b-card class="mb-4" title-tag="h6" title="Company Account Details">
            <b-row>
              <b-col>
                <base-input
                  type="select"
                  v-model="companyTempRegInfo.bank_name"
                  label="Bank Name"
                  :options="BankListOptions"
                  :disabled="readOnly"
                ></base-input>
                <base-input
                  v-if="companyTempRegInfo.bank_name === 'Other'"
                  v-model="companyTempRegInfo.others"
                  type="text"
                  label="Please state your bank name"
                ></base-input>
              </b-col>
              <b-col>
                <base-input
                  v-model="companyTempRegInfo.bank_details"
                  label="Account Number"
                  :disabled="readOnly"
                ></base-input>
                <p class="mb-3">
                  Information to be used for refund purposes (if any). Please
                  ensure that all information provided are accurate.
                </p>
              </b-col>
            </b-row>
          </b-card>
          <b-card class="mb-4" title-tag="h6" title="Company Address">
            <b-row>
              <b-col>
                <base-input
                  v-model="companyTempRegInfo.address_line_1"
                  label="Address 1"
                  :disabled="readOnly"
                ></base-input>
                <base-input
                  v-model="companyTempRegInfo.address_line_2"
                  label="Address 2"
                  :disabled="readOnly"
                ></base-input>
                <base-input
                  v-model="companyTempRegInfo.address_line_3"
                  label="Address 3"
                  :disabled="readOnly"
                ></base-input>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <base-input
                  v-model="companyTempRegInfo.postcode"
                  label="Postcode"
                  max-length="5"
                  :disabled="readOnly"
                ></base-input>
                <base-input
                  type="select"
                  v-model="companyTempRegInfo.city"
                  label="City"
                  :options="ParsedCities"
                  :disabled="readOnly"
                ></base-input>
              </b-col>
              <b-col>
                <base-input
                  type="select"
                  v-model="companyTempRegInfo.state"
                  label="State"
                  :options="ParsedStates"
                  :disabled="readOnly"
                ></base-input>
                <base-input
                  type="select"
                  v-model="companyTempRegInfo.country"
                  label="Country"
                  :options="ParsedCountries"
                  :disabled="readOnly"
                ></base-input>
              </b-col>
            </b-row>
          </b-card>
        </div>
      </template>
    </KTCard>

    <KTCard v-if="isRejectForm" title="Reject Form">
      <template #body>
        <base-input
          type="textarea"
          v-model="rejectForm"
          attribute="Reject reason"
          placeholder="Enter reason"
          :validator="$v.rejectForm"
          :messages="localMessages"
        ></base-input>

        <pre>{{ rejectForm }}</pre>
      </template>
    </KTCard>

    <!-- <pre>{{ companyTempRegInfo }}</pre> -->

    <template #modal-footer>
      <b-button @click="closeModal(modalId)" variant="secondary">
        Close
      </b-button>
      <b-button
        v-if="companyTempRegInfo.temp_reg_status_id == 2"
        @click="onRejectForm()"
        variant="danger"
      >
        {{ isRejectForm ? "Cancel Reject" : "Reject" }}
      </b-button>
      <b-button v-if="isRejectForm" @click="onSubmitReject" variant="primary">
        Submit Reject
      </b-button>
      <b-button
        v-if="companyTempRegInfo.temp_reg_status_id == 2 && !isRejectForm"
        @click="onApprove()"
        variant="primary"
      >
        Approve
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import {
  SET_MODAL_STATE,
  // SYSTEM_CONSTANTS,
} from "@/core/services/store/modules/ui.module";
import {
  APPROVE_COMPANY_TEMP_REG_INFO_LIST,
  GET_COMPANY_TEMP_REG_INFO_APPROVED_LIST,
  GET_COMPANY_TEMP_REG_INFO_PENDING_LIST,
  GET_COMPANY_TEMP_REG_INFO_REJECTED_LIST,
  REJECT_COMPANY_TEMP_REG_INFO_LIST,
} from "@/core/services/store/modules/clientadmin/administration.module";

import SystemHelper from "@/core/services/systemhelper.service";
import UiHelper from "@/core/services/uihelper.service";

import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  data() {
    return {
      modalId: "modalCompanyTempRegInfo",
      isRejectForm: false,
      rejectForm: null,
      parsedRejectForm: null,
      isLoading: true,
      readOnly: true,
      companyTempRegInfo: null,
      localMessages: UiHelper.validationMessages(),
    };
  },
  validations: {
    rejectForm: { required },
  },
  computed: {
    ParsedCities() {
      if (Object.keys(this.SystemConstants).length) {
        let states = this.SystemConstants.countryStateCityRelation[0].states;

        if (this.companyTempRegInfo.state) {
          let state = states.find(
            (s) => s.state_id === this.companyTempRegInfo.state
          );

          const parsedCity = [];
          state.cities.forEach((city) => {
            parsedCity.push({ value: city.city_id, label: city.city });
          });
          return parsedCity;
        } else {
          return [];
        }
      } else {
        return [];
      }
    },
    BankListOptions() {
      const bank = this.BankList;
      let options = [];
      if (bank.length) {
        bank.forEach((bank) => {
          options.push({ label: bank.name, value: bank.name });
        });
      }
      options.push({ label: "Other", value: "Other" });
      return options;
    },
    BankList() {
      const bank = this.SystemConstants.bankListReference;
      if (Array.isArray(bank) && bank.length) {
        return bank;
      } else {
        return [];
      }
    },
    ParsedStates() {
      if (Object.keys(this.SystemConstants).length) {
        let states = this.SystemConstants.countryStateCityRelation[0].states;
        let parsedStates = [];

        states.forEach((state) => {
          parsedStates.push({ value: state.state_id, label: state.state });
        });
        return parsedStates;
      } else {
        return [];
      }
    },
    ParsedCountries() {
      if (Object.keys(this.SystemConstants).length) {
        let countries = this.SystemConstants.country;
        let parsedCountries = [];
        countries.forEach((country) => {
          parsedCountries.push({ value: country.value, label: country.key });
        });
        return parsedCountries;
      } else {
        return [];
      }
    },
    SystemConstants() {
      const constants = this.$store.state.ui.systemConstants;
      if (
        typeof constants == "object" &&
        constants !== null &&
        Object.keys(constants).length
      ) {
        return constants.data;
      } else {
        return {};
      }
    },
  },
  methods: {
    async onShow() {
      this.isLoading = true;

      console.log(this.modalId);
      this.companyTempRegInfo = this.$parent.selectedRow;
      // get system constants
      // await this.$store.dispatch(SYSTEM_CONSTANTS);

      this.isLoading = false;
    },
    onApprove() {
      let payload = {
        temp_registration_information_id: this.companyTempRegInfo
          .temp_registration_information_id,
      };

      SystemHelper.confirmationDialogHandler(
        { title: "Confirmation", html: "Approve?" },
        { action: APPROVE_COMPANY_TEMP_REG_INFO_LIST, payload: payload },
        [
          {
            action: GET_COMPANY_TEMP_REG_INFO_PENDING_LIST,
            payload: this.defaultPayload,
          },
          {
            action: GET_COMPANY_TEMP_REG_INFO_APPROVED_LIST,
            payload: this.defaultPayload,
          },
        ],
        this.modalId
      );
    },
    onRejectForm() {
      console.log("reject form");
      this.isRejectForm = !this.isRejectForm;
    },
    onSubmitReject() {
      this.$v.rejectForm.$touch();

      if (this.$v.rejectForm.$anyError) {
        return;
      } else {
        this.parsedRejectForm = this.rejectForm.replace(/\n/g, "<br/>");
        console.log(this.parsedRejectForm);
        const payload = {
          temp_registration_information_id: this.companyTempRegInfo
            .temp_registration_information_id,
          reject_reason: this.parsedRejectForm,
        };

        SystemHelper.confirmationDialogHandler(
          { title: "CONFIRMATION", html: "Reject?" },
          { action: REJECT_COMPANY_TEMP_REG_INFO_LIST, payload: payload },
          [
            {
              action: GET_COMPANY_TEMP_REG_INFO_PENDING_LIST,
              payload: this.defaultPayload,
            },
            {
              action: GET_COMPANY_TEMP_REG_INFO_REJECTED_LIST,
              payload: this.defaultPayload,
            },
          ],
          this.modalId
        );
      }
    },
    resetModal() {
      this.isRejectForm = false;
      this.rejectForm = null;
      this.parsedRejectForm = null;
      this.$store.dispatch(SET_MODAL_STATE, null);
    },
  },
};
</script>

<style lang="scss">
.confirmation-swal.swal-txtarea .swal2-popup .swal2-content {
  margin: 1rem 0;
  padding: 1.5rem;
}
</style>
