<template>
  <div>
    <KTCard title="Company Registration Info">
      <template v-slot:body>
        <b-tabs content-class="mt-3">
          <!--pending-->
          <b-tab title="Pending" @click="tabChange" active>
            <b-row v-if="!!tableSettings">
              <b-col cols="6">
                <b-form-group class="m-2" label-size="sm">
                  <template v-if="tableSettings.refresh">
                    <b-button size="sm" class="mr-2">
                      <i class="fa fa-sync"></i> Refresh
                    </b-button>
                  </template>
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group
                  class="m-2"
                  label-cols-sm="3"
                  label-align-sm="right"
                  label-size="sm"
                >
                  <template v-if="tableSettings.filter">
                    <b-input-group size="sm">
                      <b-form-input
                        v-model="filter.firstTab"
                        type="search"
                        placeholder="Type to Search"
                      ></b-form-input>

                      <b-input-group-append>
                        <b-button
                          :disabled="!filter.firstTab"
                          @click="filter.firstTab = ''"
                          >Clear</b-button
                        >
                      </b-input-group-append>
                    </b-input-group>
                  </template>
                </b-form-group>
              </b-col>
            </b-row>
            <b-table
              :per-page="tableSettings.perPage"
              :current-page="tableSettings.currentPage"
              :filter="filter.firstTab"
              :busy="isBusy"
              striped
              bordered
              show-empty
              hover
              :items="PendingCompanyRegList"
              :fields="fields"
            >
              <template v-slot:cell(actions)="row">
                <b-button-group size="sm">
                  <b-button
                    @click="onApprove(row.item)"
                    variant="primary"
                    size="sm"
                  >
                    Approve
                  </b-button>
                  <b-button
                    @click="onReject(row.item)"
                    variant="danger"
                    size="sm"
                  >
                    Reject
                  </b-button>
                  <b-button
                    @click="onViewDetails(row.item)"
                    variant="info"
                    size="sm"
                  >
                    View
                  </b-button>
                </b-button-group>
              </template>
            </b-table>

            <b-pagination
              v-if="tableSettings.row > tableSettings.perPage"
              class="pl-0"
              v-model="currentPage"
              :total-rows="tableSettings.rows"
              :per-page="tableSettings.perPage"
            ></b-pagination>
          </b-tab>
          <!--pending-->

          <!--approved-->
          <b-tab @click="tabChange" title="Approved">
            <b-row v-if="!!tableSettings">
              <b-col cols="6">
                <b-form-group class="m-2" label-size="sm">
                  <template v-if="tableSettings.refresh">
                    <b-button size="sm" class="mr-2">
                      <i class="fa fa-sync"></i> Refresh
                    </b-button>
                  </template>
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group
                  class="m-2"
                  label-cols-sm="3"
                  label-align-sm="right"
                  label-size="sm"
                >
                  <template v-if="tableSettings.filter">
                    <b-input-group size="sm">
                      <b-form-input
                        v-model="filter.secondTab"
                        type="search"
                        placeholder="Type to Search"
                      ></b-form-input>

                      <b-input-group-append>
                        <b-button
                          :disabled="!filter.secondTab"
                          @click="filter.secondTab = ''"
                          >Clear</b-button
                        >
                      </b-input-group-append>
                    </b-input-group>
                  </template>
                </b-form-group>
              </b-col>
            </b-row>
            <b-table
              :per-page="tableSettings.perPage"
              :current-page="tableSettings.currentPage"
              :filter="filter.secondTab"
              :busy="isBusy"
              striped
              bordered
              show-empty
              hover
              :items="ApprovedCompanyRegList"
              :fields="fields"
            >
              <template v-slot:cell(actions)="row">
                <b-button
                  @click="onViewDetails(row.item)"
                  variant="info"
                  size="sm"
                >
                  View
                </b-button>
              </template>
            </b-table>

            <b-pagination
              v-if="tableSettings.row > tableSettings.perPage"
              class="pl-0"
              v-model="currentPage"
              :total-rows="tableSettings.rows"
              :per-page="tableSettings.perPage"
            ></b-pagination>
          </b-tab>
          <!--approved-->

          <!--rejected-->
          <b-tab @click="tabChange" title="Rejected">
            <b-row v-if="!!tableSettings">
              <b-col cols="6">
                <b-form-group class="m-2" label-size="sm">
                  <template v-if="tableSettings.refresh">
                    <b-button size="sm" class="mr-2">
                      <i class="fa fa-sync"></i> Refresh
                    </b-button>
                  </template>
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group
                  class="m-2"
                  label-cols-sm="3"
                  label-align-sm="right"
                  label-size="sm"
                >
                  <template v-if="tableSettings.filter">
                    <b-input-group size="sm">
                      <b-form-input
                        v-model="filter.firstTab"
                        type="search"
                        placeholder="Type to Search"
                      ></b-form-input>

                      <b-input-group-append>
                        <b-button
                          :disabled="!filter.thirdTab"
                          @click="filter.thirdTab = ''"
                          >Clear
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </template>
                </b-form-group>
              </b-col>
            </b-row>
            <b-table
              :per-page="tableSettings.perPage"
              :current-page="tableSettings.currentPage"
              :filter="filter.thirdTab"
              :busy="isBusy"
              striped
              bordered
              show-empty
              hover
              :items="RejectedCompanyRegList"
              :fields="fields"
            >
              <template v-slot:cell(actions)="row">
                <b-button-group size="sm">
                  <b-button
                    @click="onViewDetails(row.item)"
                    variant="info"
                    size="sm"
                  >
                    View
                  </b-button>
                </b-button-group>
              </template>
            </b-table>

            <b-pagination
              v-if="tableSettings.row > tableSettings.perPage"
              class="pl-0"
              v-model="currentPage"
              :total-rows="tableSettings.rows"
              :per-page="tableSettings.perPage"
            ></b-pagination>
          </b-tab>
          <!--rejected-->
        </b-tabs>
      </template>
    </KTCard>

    <!-- modals -->
    <company-temp-reg-info-modal></company-temp-reg-info-modal>
    <!-- modals -->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/modules/breadcrumbs.module";

import CompanyTempRegInfoModal from "@/view/pages/clientadmin/company/modals/CompanyTempRegInfoModal.vue";

import { validationMixin } from "vuelidate";
// import SystemHelper from "@/core/services/systemhelper.service";
import {
  APPROVE_COMPANY_TEMP_REG_INFO_LIST,
  GET_COMPANY_TEMP_REG_INFO_APPROVED_LIST,
  GET_COMPANY_TEMP_REG_INFO_PENDING_LIST,
  GET_COMPANY_TEMP_REG_INFO_REJECTED_LIST,
  REJECT_COMPANY_TEMP_REG_INFO_LIST,
} from "@/core/services/store/modules/clientadmin/administration.module";
import SystemHelper from "@/core/services/systemhelper.service";

export default {
  components: { CompanyTempRegInfoModal },
  mixins: [validationMixin],
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Client Admin" },
      { title: "Company" },
      { title: "Info" },
    ]);

    const vuexActions = [
      {
        action: GET_COMPANY_TEMP_REG_INFO_APPROVED_LIST,
        payload: this.defaultPayload,
      },
      {
        action: GET_COMPANY_TEMP_REG_INFO_PENDING_LIST,
        payload: this.defaultPayload,
      },
      {
        action: GET_COMPANY_TEMP_REG_INFO_REJECTED_LIST,
        payload: this.defaultPayload,
      },
    ];
    this.dispatchAllActions(vuexActions).then(() => {
      this.isBusy = false;
    });

    // this.$store.dispatch(GET_COMPANY_TEMP_REG_INFO_LIST, this.defaultPayload);
  },
  validations: {
    form: {},
  },
  computed: {
    tableSettings() {
      let perPage = 20;
      let listLength = 100;
      return {
        row: listLength,
        perPage: perPage,
        refresh: false,
        filter: true,
      };
    },
    PendingCompanyRegList() {
      const list = this.$store.state.clientadmin_administration
        .companyTempRegInfoPendingList;
      if (list && list.hasOwnProperty("data")) {
        list.data.forEach((item) => {
          item.temp_reg_status_id = 2;
        });
        return list.data;
      } else return [];
    },
    RejectedCompanyRegList() {
      const list = this.$store.state.clientadmin_administration
        .companyTempRegInfoRejectedList;
      if (list && list.hasOwnProperty("data")) {
        list.data.forEach((item) => {
          item.temp_reg_status_id = 3;
        });
        return list.data;
      } else return [];
    },
    ApprovedCompanyRegList() {
      const list = this.$store.state.clientadmin_administration
        .companyTempRegInfoApprovedList;
      if (list && list.hasOwnProperty("data")) {
        list.data.forEach((item) => {
          item.temp_reg_status_id = 1;
        });
        return list.data;
      } else return [];
    },
  },
  data() {
    return {
      isBusy: false,
      form: {},
      filter: {
        firstTab: null,
        secondTab: null,
        thirdTab: null,
      },
      currentPage: 1,
      fields: [
        { key: "company_id" },
        { key: "company_name" },
        { key: "bank_name" },
        { key: "actions", class: "text-right" },
      ],
    };
  },

  methods: {
    tabChange() {
      this.filter = {
        firstTab: null,
        secondTab: null,
        thirdTab: null,
      };
    },
    onApprove(rowItem) {
      this.selectedRow = rowItem;
      let payload = {
        temp_registration_information_id: this.selectedRow
          .temp_registration_information_id,
      };

      SystemHelper.confirmationDialogHandler(
        { title: "Confirmation", html: "Approve?" },
        { action: APPROVE_COMPANY_TEMP_REG_INFO_LIST, payload: payload },
        [
          {
            action: GET_COMPANY_TEMP_REG_INFO_PENDING_LIST,
            payload: this.defaultPayload,
          },
          {
            action: GET_COMPANY_TEMP_REG_INFO_APPROVED_LIST,
            payload: this.defaultPayload,
          },
        ],
        null
      );
    },
    onReject(rowItem) {
      this.selectedRow = rowItem;

      this.$swal({
        title: "Please State Reject Reason",
        input: "textarea",
        inputAttributes: {
          rows: "2",
          autocapitalize: "off",
        },
        customClass: {
          container: "confirmation-swal swal-txtarea",
          header: "bg-primary",
          confirmButton: "btn btn-sm btn-danger ml-3",
          cancelButton: "btn btn-sm btn-secondary ml-3",
          input: "form-control w-100",
        },
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonText: "Confirm Reject",
        showLoaderOnConfirm: true,
        preConfirm: (reason) => {
          let payload = {
            temp_registration_information_id:
              rowItem.temp_registration_information_id,
            approve_reason: reason,
          };
          if (reason === "") {
            this.$swal.showValidationMessage("Reason field is required");
          } else {
            return this.$store
              .dispatch(REJECT_COMPANY_TEMP_REG_INFO_LIST, payload)
              .then((response) => {
                if (response.response_code !== 2100) {
                  throw new Error(response.statusText);
                }
                return response;
              })
              .catch((error) => {
                this.$swal.showValidationMessage(`Request failed: ${error}`);
              });
          }
        },
        allowOutsideClick: () => !this.$swal.isLoading(),
      }).then((result) => {
        if (result.isConfirmed) {
          if (result.value.response_code === 2100) {
            const vuexActions = [
              {
                action: GET_COMPANY_TEMP_REG_INFO_PENDING_LIST,
                payload: this.defaultPayload,
              },
              {
                action: GET_COMPANY_TEMP_REG_INFO_REJECTED_LIST,
                payload: this.defaultPayload,
              },
            ];
            this.$dispatchAllActions(vuexActions).then(() => {
              this.isBusy = false;
            });

            SystemHelper.successHandler(result.value, null, "success");
          } else {
            if (result.value === "") {
              SystemHelper.basicSwalHandler(
                "Error",
                "Reason is empty",
                "danger"
              );
            } else {
              SystemHelper.errorHandler(result.value, null, "error");
            }
          }
        }
      });
    },
    onViewDetails(rowItem) {
      this.selectedRow = rowItem;
      this.openModal("modalCompanyTempRegInfo");
    },
  },
};
</script>
<style lang="scss">
.confirmation-swal.swal-txtarea .swal2-popup .swal2-content {
  margin: 1rem 0;
  padding: 1.5rem;
}
</style>
